import {Component, OnDestroy, OnInit} from '@angular/core';
import {News} from "../model/news.model";
import {Subject} from "rxjs";
import {CrudService} from "../shared/crud.service";
import {takeUntil} from "rxjs/operators";

@Component({
  selector: 'app-equilibrage-broche',
  templateUrl: './equilibrage-broche.component.html',
  styleUrls: ['./equilibrage-broche.component.css']
})
export class EquilibrageBrocheComponent implements OnInit, OnDestroy {
  equilibrage: News[];
  private unsubscribe$ = new Subject();
  constructor(public crudApi: CrudService) { }


  ngOnInit() {
    this.crudApi
      .getNewsQuery('page', 'Equilibrage de Broche et Electro-Broche', 100)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (news) => {
          this.equilibrage = news;
        },
        (err) => console.error(err)
      );
  }
  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
