import { Injectable } from '@angular/core';
import {AngularFireDatabase, AngularFireList, AngularFireObject} from "@angular/fire/database";
import {News} from "../model/news.model";
import {Observable, of} from "rxjs";
import {catchError, map, tap} from "rxjs/operators";
import {NewsUrl} from "../model/newsUrl";
import {fromPromise} from "rxjs/internal-compatibility";

@Injectable({
  providedIn: 'root'
})
export class CrudService {

  private newsUrl = NewsUrl.newsUrl;
  constructor(private db: AngularFireDatabase) { }


  // Fetch Single Student Object
  getNews(id: string) : Observable<News | null> {
    const url = `${this.newsUrl}/${id}`;

    return this.db
      .object<News>(url)
      .valueChanges()
      .pipe(
        tap((result) => {
          if (result) {
            return of(result);
          } else {
            return of(null);
          }
        }),
      );
  }


  public getAllNews(): Observable<News[]> {
    return this.db
      .list<News>('news', (ref) => ref.orderByChild('date'))
      .valueChanges()
      .pipe(map((arr) => arr.reverse()), catchError(this.handleError<News[]>(`getAllNews`)));
  }
  public getNewsQuery(
    byChild: string,
    equalTo: string | boolean,
    limitToFirst: number
  ): Observable<News[]> {
    return this.db
      .list<News>('news', (ref) =>
        ref
          .orderByChild(byChild)
          .equalTo(equalTo)
          .limitToFirst(limitToFirst)
      )
      .valueChanges()
      .pipe(catchError(this.handleError<News[]>(`getNewsQuery`)));
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error); // log to console instead
      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
/*  // Fetch Students List
  getAllNews() {
    return this.db.list('news');
  }*/
  public addNews(data: { news: News; }) {
    return this.db
      .list('news')
      .set(data.news.id.toString(), data.news);
  }

  public deleteNews(news: News) {
      return this.db.database.ref('news/'+news.id+'/').remove();
  }

  public updateNews(data: { news: News}) {
    const url = `${this.newsUrl}/${data.news.id}`;
    console.log("update "+data.news.activeImage)
console.log("update "+data.news.imageUrl)

    return  this.db
      .object<News>(url)
      .update(data.news);
  }
}
