import {AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {News} from "../model/news.model";
import {ActivatedRoute, Params, Router} from "@angular/router";
import {Observable, Subscription} from "rxjs";

import {CrudService} from "../shared/crud.service";
import {AngularFireStorage} from "@angular/fire/storage";


@Component({
  selector: 'app-add-edit',
  templateUrl: './add-edit.component.html',
  styleUrls: ['./add-edit.component.css']
})
export class AddEditComponent implements OnInit, OnDestroy {
  public newsForm: FormGroup;
  public news: News;
  @Input()
  showMe: boolean;


  private newsSubscription: Subscription;
  private formSubscription: Subscription;
  public id;
  public mode: 'edit' | 'add';
  isHovering: boolean;

  files: File[] = [];

  private imageSet: string[] = [];


  constructor(
    private router: Router,
    public route: ActivatedRoute,
    public crudApi: CrudService,
    private storage: AngularFireStorage
  ) {
  }

  ngOnInit() {
    this.setNews();
  }

  toggleHover(event: boolean) {
    this.isHovering = event;
  }

  onDrop(files: FileList) {
    for (let i = 0; i < files.length; i++) {
      this.files.push(files.item(i));

    }
  }

  removeFiles() {
    this.news.imageUrl = [];
    for (let i = 0; i < this.files.length; i++) {
      this.files.pop();
    }
  }

  deleteImgUrl(msg: string) {
    const index: number = this.news.imageUrl.indexOf(msg);
    console.log("index image Url " + index)
    if (index !== -1) {
      this.news.imageUrl.splice(index, 1);
    }
  }

  deleteImgSet(msg: string) {
    const index: number = this.imageSet.indexOf(msg);

    if (index !== -1) {
      this.imageSet.splice(index, 1);
    }
  }

  removeImageSet(image: string) {
    console.log("removing this image " + image)
    this.deleteImgSet(image)
    if (image == this.news.activeImage) {
      this.news.activeImage = '';
      this.news.activeImage = '';
      console.log("active image supprimée " + this.news.activeImage)
    } else {
      this.deleteImgUrl(image)
    }
    console.log("active image supprimée " + this.news.activeImage)
    this.storage.storage.refFromURL(image).delete();
  }


  private setNews() {
    this.route.params.subscribe((params: Params) => {
      this.id = +this.route.snapshot.paramMap.get('id');
      // if we have an id, we're in edit mode
      if (this.id) {
        this.mode = 'edit';
        this.getNews(this.id);
        this.initForm();
      } else {
        // else we are in add mode
        this.mode = 'add';
        this.constructNews();
        this.initForm();
      }
    });
  }


  private constructNews() {
    const product = this.constructMockNews();
    this.syncNews(product);
    this.initForm();
  }


  private constructMockNews() {
    return new News();
  }

  private getNews(id): void {
    this.newsSubscription = this.crudApi
      .getNews(id)
      .subscribe((news) => {
        if (news) {
          if (news.imageUrl ==null) {
            news.imageUrl = []
          }
          this.syncNews(news);
          this.initForm();
          console.log("activeImage " + this.news.activeImage)
          console.log("imageUrl " + this.news.imageUrl)

          if (this.news.imageUrl) {
            var imageVar = this.news.imageUrl;
            this.imageSet = [...imageVar];
          }
          var el = this.news.activeImage
          this.imageSet.push(el);
        }
      });
  }

  private initForm() {
    this.newsForm = new FormGroup({
      titre: new FormControl(
        this.news && this.news.titre,
        Validators.required
      ),
      description: new FormControl(
        this.news && this.news.description,
        Validators.required
      ),
      id: new FormControl(
        {
          value: this.news && this.news.id,
          disabled: true
        },
        [Validators.required, Validators.min(0)]
      ),
      date: new FormControl(
        this.news && this.news.date,
        Validators.required
      ),
      page: new FormControl(
        this.news && this.news.page,
        Validators.required)/*,
      imageUrl: new FormControl(
        {
          value: this.news && this.news.imageUrl,
          disabled: true
        },
        Validators.required
      )*/
    });
    // this.newsForm.controls['page'].setValue(this.default, {onlySelf: true});
    this.onFormChanges();

  }

  private onFormChanges() {
    this.formSubscription = this.newsForm.valueChanges.subscribe(
      (formFieldValues) => {
        const news = {...this.news, ...formFieldValues};
        this.syncNews(news);
      }
    );
  }

  public onSubmit() {
    this.syncNews({...this.news, ...this.newsForm.value});
    const newsToSubmit = this.constructProductToSubmit(this.news);
    if (this.mode === 'add') {
      this.addNews(newsToSubmit);
    } else if (this.mode === 'edit') {
      this.updateNews(newsToSubmit);
    }
    this.newsForm.reset();
    this.removeFiles();


  }

  goToDashBoard() {
    this.router.navigate(['/admin']);
  }

  addNewEntry(event) {
    console.log("active image " + this.news.activeImage )
    if (this.news.activeImage) {
      this.news.imageUrl.push(event.value);
    } else {
      this.news.activeImage = event.value;
    }
    console.log(this.news.imageUrl+"999999999999")
    this.syncNews({...this.news, ...this.newsForm.value});
  }

  private addNews(news: News) {
    this.crudApi.addNews({news});
    this.news = null;
    this.goToDashBoard();
  }

  private updateNews(news: News) {
    this.newsSubscription.unsubscribe();
    console.log(news.activeImage + "4444")
    this.crudApi.updateNews({news})
    this.router.navigate(['/admin']);
  }

  private syncNews(news): void {

    const id = this.createId(news);

    this.news = {
      ...news,
      id
    };

  }


  private createId(news: News): number {
    const randomId = Math.floor(Math.random() * new Date().getTime());
    let id = news.id || randomId;
    if (id === 1) {
      id = randomId;
    }
    return id;
  }

  private constructProductToSubmit(product: News): News {
    return {
      ...product

    };
  }

  ngOnDestroy() {
    this.formSubscription.unsubscribe();
  }
}
