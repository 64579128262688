import {AfterViewInit, Component, Input, OnInit} from '@angular/core';
import {News} from "../model/news.model";

declare function resetOrientation(): any;

@Component({
  selector: 'app-article',
  templateUrl: './article.component.html',
  styleUrls: ['./article.component.css']
})
export class ArticleComponent implements OnInit, AfterViewInit {

  @Input()
  newsEntity: News;


  constructor() { }

  ngOnInit(): void {

  }

  ngAfterViewInit(): void {
    console.log("***** " +this.newsEntity.id)
    resetOrientation()
  }

}
