import {Component, OnDestroy, OnInit} from '@angular/core';
import {NgbCarouselConfig} from '@ng-bootstrap/ng-bootstrap';
import {News} from "../model/news.model";
import {Subject} from "rxjs";
import {CrudService} from "../shared/crud.service";
import {takeUntil} from "rxjs/operators";

@Component({
  selector: 'app-accueil',
  templateUrl: './accueil.component.html',
  styleUrls: ['./accueil.component.css'],
  providers: [NgbCarouselConfig]
})
export class AccueilComponent implements OnInit, OnDestroy {
  public imagesLoaded: string[];
  public activeImageUrl: string;
  public activeImageElect: string;
  public activeImageEq: string;
  public activeImageDepl: string;
  public activeImageAnalyse: string;
  public activeImageMec: string;

  electriques: News;
  hydroliques: News;
  unsubscribe$ = new Subject();
  equilibrage: News;
  deplacements: News;
  analyses: News;
  mecaniques: News;

  constructor(public crudApi: CrudService) {
  }


  ngOnInit() {
    this.imagesLoaded = [];
    this.crudApi
      .getNewsQuery('page', 'Intervention Hydraulique et Pneumatique', 100)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (news) => {
          this.hydroliques = news[0];
          this.activeImageUrl = this.hydroliques.activeImage;
        },
        (err) => console.error(err)
      );
    this.crudApi
      .getNewsQuery('page', 'Intervention Eléctrique', 100)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (news) => {
          this.electriques = news[0];
          this.activeImageElect = this.electriques.activeImage;
        },
        (err) => console.error(err)
      );

    this.crudApi
      .getNewsQuery('page', 'Equilibrage de Broche et Electro-Broche', 100)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (news) => {
          this.equilibrage = news[0];
          this.activeImageEq = this.equilibrage.activeImage;
        },
        (err) => console.error(err)
      );
    this.crudApi
      .getNewsQuery('page', 'Déplacements et transfert machines', 100)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (news) => {
          this.deplacements = news[0];
          this.activeImageDepl = this.deplacements.activeImage;
        },
        (err) => console.error(err)
      );
    this.crudApi
      .getNewsQuery('page', 'Analyse Vibratoire', 100)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (news) => {
          this.analyses = news[0];
          this.activeImageAnalyse = this.analyses.activeImage;
        },
        (err) => console.error(err)
      );
    this.crudApi
      .getNewsQuery('page', 'Intervention Mécanique', 100)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (news) => {
          this.mecaniques = news[0];
          this.activeImageMec = this.mecaniques.activeImage;
        },
        (err) => console.error(err)
      );

  }
  public onImageLoad(e: any) {
    this.imagesLoaded.push(e.target.src);
  }
  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
