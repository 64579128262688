import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {News} from "../model/news.model";

import {CrudService} from "../shared/crud.service";
import {takeUntil} from "rxjs/operators";
import {Subject} from "rxjs";


@Component({
  selector: 'app-projet',
  templateUrl: './projet.component.html',
  styleUrls: ['./projet.component.css']
})
export class ProjetComponent implements OnInit, OnDestroy {
  p: number = 1;
  @Input()
  newsList: News[];
  private unsubscribe$ = new Subject();

  constructor(public crudApi: CrudService
  ) {

  }

  ngOnInit() {
    this.getNouveautes();
  }

  getNouveautes() {

    this.crudApi
      .getNewsQuery('page', 'Actualités', 100)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (news) => {
          this.newsList = news;
        },
        (err) => console.error(err)
      );
  }

  getActiveImage(news: News) {
    if (!news.activeImage && news.imageUrl.length == 0) {
      return '';
    }
    if (!news.activeImage) {
      return news.imageUrl[0];
    }
    return news.activeImage;
  }

  getImageUrl(imageUrl: String, news: News) {
    if (!imageUrl) {
      return news.activeImage;
    }
    return imageUrl;
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
