import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {Subscription} from "rxjs";
import {EmailService} from "../email.service";

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css']
})
export class ContactUsComponent implements OnInit, OnDestroy{
  public subscription: Subscription;
  contactUsForm: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private sendmailservice: EmailService
  ) {}

  ngOnInit() {
    this.contactUsForm =  this.formBuilder.group({
      firstName: ['', [
        Validators.required,
        Validators.minLength(3)
      ]
      ],
      lastName: ['', [
        Validators.required,
        Validators.minLength(3)
      ]
      ],
      comments: ['', [
        Validators.required,
        Validators.minLength(3)
      ]
      ],
      mob: ['', [
        Validators.required,
        Validators.minLength(10)
      ]
      ],
      email: ['', [
        Validators.required,
        Validators.email
      ]
      ]
    });

  }
  get firstName() { return this.contactUsForm.get('firstName'); }
  get lastName() { return this.contactUsForm.get('lastName'); }
  get comments() { return this.contactUsForm.get('comments'); }
  get mob() { return this.contactUsForm.get('mob'); }
  get email() { return this.contactUsForm.get('email'); }

  sendMail() {
    this.subscription = this.sendmailservice.sendEmail(this.contactUsForm.value).
    subscribe(data => {
      let msg = data['message']

      // console.log(data, "success");
    }, error => {
      console.error(error, "error");
    } );
    alert('Message envoyé avec succès');
  }
  ngOnDestroy() {
  }

}
