import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {Iinfo} from "./interface/info.model";

@Injectable({
  providedIn: 'root'
})
export class EmailService {

  constructor(private http: HttpClient) { }

  sendEmail(obj): Observable<Iinfo> {
    return this.http.post<Iinfo>('https://us-central1-smi-4fec6.cloudfunctions.net/sendMail', obj,{responseType: 'json'})
  }
}
