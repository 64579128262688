import {Component, OnDestroy, OnInit} from '@angular/core';
import {News} from "../model/news.model";
import {Subject} from "rxjs";
import {CrudService} from "../shared/crud.service";
import {takeUntil} from "rxjs/operators";

@Component({
  selector: 'app-analyse-vibr',
  templateUrl: './analyse-vibr.component.html',
  styleUrls: ['./analyse-vibr.component.css']
})
export class AnalyseVibrComponent implements OnInit, OnDestroy {
  analyses: News[];
  private unsubscribe$ = new Subject();
  constructor(public crudApi: CrudService) { }


  ngOnInit() {
    this.crudApi
      .getNewsQuery('page', 'Analyse Vibratoire', 100)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (news) => {
          this.analyses = news;
        },
        (err) => console.error(err)
      );
  }
  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
