import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {CrudService} from "../shared/crud.service";
import {News} from "../model/news.model";
import {Subject} from "rxjs";

import {takeUntil} from "rxjs/operators";
import {FormBuilder, Validators} from "@angular/forms";
import {AngularFireStorage} from "@angular/fire/storage";
import {Router} from "@angular/router";

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit, OnDestroy {

  public newsList: News[];
  public cachedNewsList: News[];
  unsubscribe$ = new Subject();
  public pages: any[];
  newsLoading: boolean;
  isSubmitted = false;

  constructor(private router: Router, public crudApi: CrudService,
              public fb: FormBuilder, private storage: AngularFireStorage) {

    this.pages = ['Nouveautés', 'Intervention Eléctrique', 'Intervention Mécanique', 'Analyse Vibatoire']
  }

  ngOnInit() {
    this.getProducts();
  }

  getProducts() {
    this.newsLoading = true;
    this.crudApi.getAllNews()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((products) => {

        this.newsList = <News[]>products;
        this.cachedNewsList = <News[]>products;
      });
  }

  filterPages(event) {

    if (event == 'ALL') {
      this.newsList = this.cachedNewsList;
    } else {

      this.crudApi
        .getNewsQuery('page', event, 100)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(
          (news) => {
            this.newsList = news;
          },
          (err) => console.error(err)
        );
    }
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  removeEntry(news: News) {

    if (news.activeImage) {
      this.storage.storage.refFromURL(news.activeImage).delete();
    }
    if (news.imageUrl) {

      news.imageUrl.forEach(value => {
        this.storage.storage.refFromURL(value).delete();

      })
    }
    this.crudApi.deleteNews(news);
  }
}

