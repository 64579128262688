import {Component, OnDestroy, OnInit} from '@angular/core';
import {News} from "../model/news.model";
import {Subject} from "rxjs";
import {CrudService} from "../shared/crud.service";
import {takeUntil} from "rxjs/operators";

@Component({
  selector: 'app-intervention-electrique',
  templateUrl: './intervention-electrique.component.html',
  styleUrls: ['./intervention-electrique.component.css']
})
export class InterventionElectriqueComponent implements OnInit, OnDestroy {

  electriques: News[];
  private unsubscribe$ = new Subject();
  constructor(public crudApi: CrudService) { }


  ngOnInit() {
    this.crudApi
      .getNewsQuery('page', 'Intervention Eléctrique', 100)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (news) => {
          this.electriques = news;
        },
        (err) => console.error(err)
      );
  }
  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
