import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {News} from "../model/news.model";
import {Subject} from "rxjs";
import {CrudService} from "../shared/crud.service";
import {takeUntil} from "rxjs/operators";
import {ActivatedRoute, Params} from "@angular/router";

@Component({
  selector: 'app-service-detail',
  templateUrl: './service-detail.component.html',
  styleUrls: ['./service-detail.component.css']
})
export class ServiceDetailComponent implements OnInit , OnDestroy {
  p: number = 1;
  public page;
  @Input()
  servicesList: News[];
  private unsubscribe$ = new Subject();
  constructor(public crudApi: CrudService,
              private route: ActivatedRoute
  ) {

  }

  ngOnInit() {
    this.route
      .queryParams
      .subscribe(params => {
        // Defaults to 0 if no query param provided.
        this.page = params['page'];
        this.crudApi
          .getNewsQuery('page', this.page, 100)
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe(
            (news) => {
              this.servicesList = news;
            },
            (err) => console.error(err)
          );
      });


  }
  getActiveImage(news: News) {
    if (!news.activeImage && news.imageUrl.length == 0) {
      return '';
    }
    if (!news.activeImage) {
      return news.imageUrl[0];
    }
    return news.activeImage;
  }
  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
