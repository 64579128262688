export class News {
  public page?: string;

  constructor(
    public id: number = 1,
    public date: string = new Date().toISOString().split('T')[0],
    public titre: string = '',
    public description: string = '',
    public activeImage: string = '',
    public imageUrl:  string[] = []
  ) {}
}
