import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BaseLayoutComponent } from './base-layout/base-layout.component';
import { FooterComponent } from './footer/footer.component';
import { RouterModule, Routes } from '@angular/router';
import { AccueilComponent } from './accueil/accueil.component';
import { ContactComponent } from './contact/contact.component';
import { ProjetComponent } from './projet/projet.component';
import {AgmCoreModule} from '@agm/core';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { AgmDirectionModule } from 'agm-direction'
import { AngularFireModule } from '@angular/fire';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { environment } from '../environments/environment';
import { EntrepriseComponent } from './entreprise/entreprise.component';
import { AddEditComponent } from './add-edit/add-edit.component';
import {ReactiveFormsModule} from "@angular/forms";
import { FormsModule } from '@angular/forms';
import { DropzoneDirective } from './dropzone.directive';
import { UploadTaskComponent } from './upload-task/upload-task.component';
import {AngularFireStorage} from "@angular/fire/storage";
import {AngularFirestore} from "@angular/fire/firestore";
import { TopBarComponent } from './top-bar/top-bar.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import {BsDropdownModule, ModalModule, TooltipModule} from "ngx-bootstrap";
import { InterventionElectriqueComponent } from './intervention-electrique/intervention-electrique.component';
import { InterventionMecComponent } from './intervention-mec/intervention-mec.component';
import { InterventionHydroComponent } from './intervention-hydro/intervention-hydro.component';
import { AnalyseVibrComponent } from './analyse-vibr/analyse-vibr.component';
import { EquilibrageBrocheComponent } from './equilibrage-broche/equilibrage-broche.component';
import { DeplacementComponent } from './deplacement/deplacement.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { HttpClientModule } from '@angular/common/http';
import { ServiceDetailComponent } from './service-detail/service-detail.component';
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {NgInitDirective} from "./model/NgInitDirective";
import { ArticleComponent } from './article/article.component';
const appRoutes : Routes =[
  {path: '',redirectTo:'accueil', pathMatch:'full'},
  {path : 'accueil', component : AccueilComponent},
  {path : 'news/add', component : AddEditComponent},
  {path : 'entreprise', component : EntrepriseComponent},
  {path : 'contact', component : ContactComponent},
  {path : 'projets', component : ProjetComponent},
  {path : 'admin', component : DashboardComponent},
  {path : 'mecanique', component : InterventionMecComponent},
  {path : 'electrique', component : InterventionElectriqueComponent},
  {path : 'hydraulique', component : InterventionHydroComponent},
  {path : 'analyse-vibratoire', component : AnalyseVibrComponent},
  {path : 'equilibrage-broche', component : EquilibrageBrocheComponent},
  {path : 'deplacement-transfert-machine', component : DeplacementComponent},
  {path : 'service-detail', component : ServiceDetailComponent},
  {
    path: 'news/:id',
    component: AddEditComponent
  }
  ]

@NgModule({
  declarations: [
    AppComponent,
    BaseLayoutComponent,
    FooterComponent,
    AccueilComponent,
    ContactComponent,
    ProjetComponent,
    EntrepriseComponent,
    AddEditComponent,
    DropzoneDirective,
    UploadTaskComponent,
    TopBarComponent,
    DashboardComponent,
    InterventionElectriqueComponent,
    InterventionMecComponent,
    InterventionHydroComponent,
    AnalyseVibrComponent,
    EquilibrageBrocheComponent,
    DeplacementComponent,
    ContactUsComponent,
    ServiceDetailComponent,
    NgInitDirective,
    ArticleComponent
  ],
  imports: [
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireDatabaseModule,
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NgbModule,
    FormsModule,
    AgmDirectionModule,
    RouterModule.forChild(appRoutes),
    AgmCoreModule.forRoot({
      apiKey: "",
      libraries: ["places", "geometry"]
      /* apiKey is required, unless you are a premium customer, in which case you can use clientId */
    }),
    ReactiveFormsModule,
    BrowserModule,
    BsDropdownModule.forRoot(),
    TooltipModule.forRoot(),
    ModalModule.forRoot()
  ],
  providers: [AngularFireStorage,AngularFirestore],
  bootstrap: [AppComponent]
})
export class AppModule { }
